<template>
  <div>
    <b-modal
      id="modalCustomers"
      hide-footer
      centered
      size="lg"
      v-model="modalShow"
    >
      <b-form @submit.prevent>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            id="input-client"
            v-model="searchCustomer"
            :placeholder="$i18n.messages.selectClient"
            @keyup="validationForm"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="validationForm">{{
              $i18n.messages.Buscar
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <div class="mt-2">
        <b-table
          :fields="fieldsFrecuentes"
          :items="listCustomersFrecuentes"
          v-if="!searchCustomer"
        >
          <template #cell(NOMBREEMPRESA)="data">
            <span class="cursor-pointer" @click="setCurrentCustomer(data.item)">
              {{ data.value }}
              <feather-icon
                class="text-body float-right"
                icon="ShoppingCartIcon"
                size="16"
                v-if="data.item.carrito"
              />
            </span>
          </template>
        </b-table>
        <b-table :fields="fields" :items="listCustomers">
          <template #cell(NOMBREEMPRESA)="data">
            <span class="cursor-pointer" @click="setCurrentCustomer(data.item)">
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BButton,
  BFormInput,
  BInputGroup,
  BModal,
  BInputGroupAppend,
  VBModal,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
  },
  computed: {
    ...mapGetters({
      listCustomers: "customers/getListCustomers",
      listCustomersFrecuentes: "customers/getListCustomersFrecuentes",
      currentCustomer: "customers/getCurrentCustomer",
      user: "auth/getUser",
    }),
    modalShow: {
      get() {
        return this.$store.getters["customers/getShowModalCustomers"];
      },
      set(val) {
        this.$store.commit("customers/setShowModalCustomers", val);
      },
    },
  },
  watch: {
    modalShow() {
      if (this.modalShow) {
        if (this.user.rol_id === 0) {
          this.searchCustomersAdmin(this.searchCustomer);
        } else {
          this.searchCustomers(this.searchCustomer);
        }
      }
    },
  },
  created() {
    this.modalShow = false;
    if (this.currentCustomer === "" || this.currentCustomer === null) {
      if (this.user.rol_id === 0) {
        this.searchCustomersAdmin(this.searchCustomer);
      } else {
        this.searchCustomers(this.searchCustomer);
      }
      /*  this.modalShow = true; */
    }
  },
  data() {
    return {
      searchCustomer: "",
      fields: [
        {
          key: "NOMBREEMPRESA",
          label: this.$i18n.messages.Todos,
        },
      ],
      fieldsFrecuentes: [
        {
          key: "NOMBREEMPRESA",
          label: this.$i18n.messages.UsoFrecuente,
        },
      ],
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    ...mapActions({
      searchCustomers: "customers/searchCustomers",
      searchCustomersAdmin: "customers/searchCustomersAdmin",
    }),
    ...mapMutations({
      setCurrentCustomer: "customers/setCurrentCustomer",
    }),
    validationForm() {
      if (this.user.rol_id === 0) {
        this.searchCustomersAdmin(this.searchCustomer);
      } else {
        this.searchCustomers(this.searchCustomer);
      }
    },
  },
};
</script>
